export const INFO = {
  title: 'Condomínio Fanqueiro 20.24',
  description:
    'Comdonínio Fanqueiro 20.24 - moradias novas T3 para venda em Loures. Casas para venda em Loures.',
  mainAction: 'ver moradias',
  logoHome: 'img/logo-home.png',
  logoMenu: 'img/logo-menu.png',
  logoCompany: 'img/logo-company.png',
  altCompany: 'Logo linhas invertidas',
  footerMsg: 'Todos os direitos reservados a Linhas Invertidas, Lda.',
  notFoundMessage:
    'A página que tentou aceder não existe ou a sua localização mudou!',
  notFoundHomeLink: 'aceder à página inicial',
  comingSoonImage: 'img/home/home-1.jpg',
  comingSoonMessage: 'Brevemente disponível',
  comingSoonFlag: true,
  organizationEmail: 'linhasinvertidas.geral@gmail.com',
  organizationPhone1: '219 824 082',
  organizationPhone2: '914 444 175',
  personalEmail: 'linhasinvertidas.geral@gmail.com',
  address1: 'R. Dr. Henrique Barbas de Albuquerque',
  address2: '2670-457 Loures, Portugal',
  // videoId: `${process.env.REACT_APP_BASE_URL}/video/video-quinta-inquisidor.mp4`
  pdfUrl: 'mapa-acabamentos.pdf',
};
