import React, { Component } from 'react';
import Navigator from '../../components/navigator/navigator.component';
import { HOUSES } from '../../data/houses';
import { Link } from 'react-router-dom';
import ImageGallery from 'react-image-gallery';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Footer from '../../components/footer/footer.component';
import * as queryString from 'querystring';
import VideoDialog from '../../components/video-dialog/video-dialog.component';
class HouseDetail extends Component {
  constructor(props) {
    super(props);
    const query = queryString.parse(props.location.search.substr(1)); // removes ?
    const house = HOUSES.housesDB.lot1.houses[query.houseIndex];
    this.state = {
      query,
      queryString: props.location.search,
      house,
      blueprintIndex: -1,
    };
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll, true);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll, true);
  }

  render() {
    console.log(this.state.house.detailSlider);
    return (
      <div className="lg:h-full lg:h-full w-full flex lg:flex-row">
        <Navigator active={'Moradias'} {...this.props} />
        {this.renderTitleDesktop()}
        <div className="w-full h-full lg:overflow-auto lg:mt-0 scroll-container">
          <div className="house-slider w-full lg:mt-0 min-h-fit-content">
            <ImageGallery
              className="w-full"
              items={this.state.house.detailSlider}
              showNav={false}
              showThumbnails={false}
              showPlayButton={false}
              showBullets={true}
              showFullscreenButton={false}
              autoPlay={true}
            />
          </div>
          {this.renderTitleMobile()}
          <div className="flex flex-col lg:flex-row justify-center items-center w-full min-h-fit-content mt-10 lg:mt-0">
            <div className="hidden lg:block mt-10 ">
              {this.renderBlueprints()}
            </div>
            <div className="block lg:hidden h-auto w-full flex flex-col items-center justify-center align-center">
              {this.renderBlueprintsMob()}
            </div>
          </div>
          <div className="w-full lg:w-2/3 m-auto min-h-fit-content text-center flex flex-col md:flex-row items-center justify-center mb-24">
            <a
              href={this.state.house.mapaAcabamentos}
              target="_blank"
              rel="noopener noreferrer"
              className="btn btn-pdf mt-5 bg-theme-btn"
            >
              mapa de acabamentos
            </a>
            <Link
              to={{
                pathname: '/book-home',
                search: this.state.queryString,
              }}
              className="btn mt-5 btn-with-icon md:ml-24 bg-theme-btn"
            >
              marcar visita
            </Link>
            <div className="mt-5 md:ml-24">
              <div className="hidden md:block">
                <VideoDialog buttonText="ver vídeo" />
              </div>
              <div className="block md:hidden">
                <VideoDialog buttonText="ver vídeo" width={'100%'} />
              </div>
            </div>
          </div>
          <Footer className="relative pl-10" />
        </div>
      </div>
    );
  }

  renderTitle = () => {
    return (
      <span>
        {!this.state.hideTitle && (
          <span>
            <h2 className="lg:text-3xl sm:lg">{this.state.house.title}</h2>
            <h1 className="lg:text-5xl sm:xl uppercase font-semibold mt-0 mb-5">
              interior
            </h1>
          </span>
        )}
        <Link
          to={{
            pathname: '/houses',
          }}
          className="btn mt-5 btn-back"
        >
          <FontAwesomeIcon icon="chevron-left" /> regressar
        </Link>
      </span>
    );
  };

  renderTitleMobile = () => (
    <span className="block lg:hidden w-5/6 m-auto min-h-fit-content mt-6">
      {this.renderTitle()}
    </span>
  );

  renderTitleDesktop = () => (
    <span className="hidden lg:block title-desktop absolute z-10 lg:flex flex-col sm:pt-10 sm: mt-10 lg:mt-72 text-white ml-menu-title lg:h-64 lg:w-1/3">
      {this.renderTitle()}
    </span>
  );

  renderBlueprints = () => {
    return this.state.house.blueprints.map((blueprint, index) => (
      <img
        key={index}
        alt={blueprint}
        className={`w-blueprint ${index !== 0 ? 'ml-4' : ''}`}
        src={blueprint}
      />
    ));
  };

  renderBlueprintsMob = () => {
    return this.state.house.blueprintsMob.map((blueprint, index) => (
      <img key={index} alt={blueprint} src={blueprint} />
    ));
  };

  handleScroll = () => {
    const scrollContainer = document.querySelector('.scroll-container');
    const houseSlider = document.querySelector('.house-slider');
    const zoomRate = 0.25;
    if (scrollContainer && houseSlider) {
      if (scrollContainer.scrollTop >= houseSlider.clientHeight * zoomRate) {
        this.setState({ hideTitle: true });
      } else {
        this.setState({ hideTitle: false });
      }
    }
  };
}
export default HouseDetail;
