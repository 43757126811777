import Home from '../screens/home/home.screen';
import ContactUs from '../screens/contact-us/contact-us.screen';
import Houses from '../screens/houses/houses.screen';
import Location from '../screens/location/location.screen';
import HouseDetail from '../screens/house-detail/house-detail.screen';
import BookHome from '../screens/book-home/book-home.screen';
import Gallery from '../screens/gallery/gallery.screen';

const ROUTERS = [
  {
    path: '/',
    component: Home,
    exact: true,
    name: 'Home',
    hideMenu: true,
  },
  {
    path: '/houses',
    component: Houses,
    name: 'Moradias',
    icon: 'i-houses',
  },
  {
    path: '/gallery',
    component: Gallery,
    name: 'Galeria',
    hideMenu: false,
  },
  {
    path: '/house-detail',
    component: HouseDetail,
    name: 'Detalhe Moradias',
    hideMenu: true,
  },
  {
    path: '/book-home',
    component: BookHome,
    name: 'Marcar Visita',
    hideMenu: true,
  },
  {
    path: '/location',
    component: Location,
    name: 'Localização',
    icon: 'i-location',
  },
  {
    path: '/contact-us',
    component: ContactUs,
    name: 'Contactos',
    icon: 'i-contacts',
  },
];

export default ROUTERS;
