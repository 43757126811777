import React, { Component } from 'react';
import * as queryString from 'querystring';
import { Link } from 'react-router-dom';

class HousesRow extends Component {
  render() {
    const { lot } = this.props;
    const available = !!lot.available;
    return (
      <div className="houses-grid h-houses-grid w-full lg:pin-b lg:absolute lg:mb-20px min-h-fit-content">
        <div className="md:w-lot items-center m-auto p-5 mt-5">
          {!available && (
            <div className="available-soon-mark w-lot">
              <h3>{lot.availableText}</h3>
            </div>
          )}
          <div className="lot-title bg-theme-primary p-2 text-white text-center font-bold mt-1 md:mt-0">
            {lot.title}
          </div>
          <div className="flex flex-col lg:flex-row lg:flex-wrap justify-around ">
            {lot.houses.map((house, index) => (
              <div
                key={`house-${index}`}
                className={`flex flex-col align-center justify-center p-2 h-12 m-5 text-center mb-2 text-theme-text ${
                  house.sold
                    ? 'bg-theme-house-btn-sold font-bold sold'
                    : 'bg-theme-house-btn'
                } rounded-lg`}
              >
                {!available && (
                  <div className={`cursor-pointer text-theme-text `}>
                    <span className="text-xs font-light">
                      <p>{house.title}</p>
                    </span>
                  </div>
                )}
                {available && house.status === 'sold' && (
                  <div className="lg:w-house-btn lg:p-2">
                    <span className="font-bold text-white">vendido</span>
                  </div>
                )}
                {available && house.status === 'booked' && (
                  <div className="lg:w-house-btn lg:p-2">
                    <span className="font-bold text-white">reservado</span>
                  </div>
                )}
                {available &&
                  house.status !== 'sold' &&
                  house.status !== 'booked' && (
                    <Link
                      className={`cursor-pointer text-theme-text lg:w-house-btn h-12 leading-loose`}
                      to={{
                        pathname: '/house-detail',
                        search: queryString.stringify({
                          houseIndex: index,
                        }),
                      }}
                    >
                      <div>
                        <span className="font-bold text-white">
                          {house.title}
                        </span>
                      </div>
                    </Link>
                  )}
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }
}

export default HousesRow;
