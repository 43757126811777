import React, { Component } from 'react';
import { mailSender } from '../../config';
import { getFloorDescription } from '../../utils/houses';
import { INFO } from '../../data/general-info';
import Modal from 'react-responsive-modal';
import TermsConditions from '../terms-conditions/terms-conditions.component';

class ContactForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      message: props.defaultMessage ? props.defaultMessage : '',
      fromEmail: '',
      fromName: '',
      fromPhoneNumber: '',
      isLoading: false,
      subjectType: props.subjectType,
      lotType: props.lotTitle ? props.lotTitle.toUpperCase() : undefined,
      acceptTermsAndConditions: false,
      isTermsModalOpen: false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  renderMessageTypeRadioButtons() {
    return (
      <div className="flex flex-wrap flex-row -mx-3 mb-6 w-full">
        <div
          onChange={e => this.setState({ subjectType: e.target.value })}
          className="w-full lg:w-1/2 px-3 mb-6 lg:mb-0"
        >
          <input id="chekboxInfo" type="radio" name="contact" value="info" />
          <label
            htmlFor="chekboxInfo"
            className="ml-2 text-white text-xl font-semibold"
          >
            PEDIR INFORMAÇÕES
          </label>
        </div>
        <div
          onChange={e => this.setState({ subjectType: e.target.value })}
          className="w-full lg:w-1/2 px-3 mb-6 lg:mb-0"
        >
          <input id="checkboxBook" type="radio" name="contact" value="book" />
          <label
            htmlFor="checkboxBook"
            className="ml-2 text-white text-xl font-semibold"
          >
            MARCAR VISITA
          </label>
        </div>
      </div>
    );
  }

  render() {
    const {
      message,
      fromEmail,
      fromName,
      fromPhoneNumber,
      isLoading,
      isTermsModalOpen,
    } = this.state;

    return (
      <div className="w-full flex justify-end lg:pr-32">
        <div className="lg:w-1/2 sm:w-full max-w-md">
          <div className="flex flex-wrap -mx-3 mb-6">
            <div className="w-full lg:w-1/2 px-3 mb-6 lg:mb-0">
              <input
                className="appearance-none block w-full opacity-75 bg-black text-white py-3 px-4 leading-tight focus:outline-none h-8"
                type="text"
                placeholder="nome"
                value={fromName}
                onChange={({ target }) =>
                  this.handleChange('fromName', target.value)
                }
              />
            </div>
            <div className="w-full lg:w-1/2 px-3">
              <input
                className="appearance-none block w-full opacity-75 bg-black text-white py-3 px-4 leading-tight focus:outline-none h-8"
                type="number"
                placeholder="telefone"
                value={fromPhoneNumber}
                onChange={({ target }) =>
                  this.handleChange('fromPhoneNumber', target.value)
                }
              />
            </div>
          </div>
          <div className="flex flex-wrap -mx-3 mb-6">
            <div className="w-full px-3">
              <input
                className="appearance-none block w-full opacity-75 bg-black text-white py-3 px-4 leading-tight focus:outline-none h-8"
                type="email"
                placeholder="e-mail"
                value={fromEmail}
                onChange={({ target }) =>
                  this.handleChange('fromEmail', target.value)
                }
              />
            </div>
          </div>
          <div className="flex flex-wrap -mx-3 mb-6">
            <div className="w-full px-3">
              <textarea
                className="appearance-none block h-24 w-full opacity-75 bg-black text-white py-3 px-4 leading-tight focus:outline-none"
                rows="8"
                type="text"
                placeholder="mensagem"
                value={message}
                onChange={({ target }) =>
                  this.handleChange('message', target.value)
                }
              />
            </div>
          </div>
          <div className="flex justify-end">
            <input
              onChange={target => {
                this.setState(prevState => {
                  return {
                    acceptTermsAndConditions: !prevState.acceptTermsAndConditions,
                  };
                });
              }}
              type="checkbox"
              id="termsAndConditionsCheckbox"
            />
            <label className="pr-2 pl-2 text-white italic text-sm">
              Estou de acordo com os{' '}
              <span
                className="underline font-bold"
                onClick={() => this.handleChange('isTermsModalOpen', true)}
              >
                {' '}
                termos e condições
              </span>{' '}
              e a Política de Privacidade do vendedor, a qual li e compreendi.
            </label>
            <button
              className="btn w-64 pr-2 pl-2 text-xl"
              onClick={this.handleSubmit}
            >
              {isLoading ? 'a enviar' : 'enviar'}
            </button>
          </div>
          <div className="w-full mt-6 flex flex-row sm: flex-column items-center">
            <div className="w-1/2 sm:w-full text-left">
              <h4 className="text-white hidden md:block">
                {INFO.organizationPhone1} <span className="ml-2 mr-2">|</span>
                {INFO.organizationPhone2}
                <br />
                {INFO.organizationEmail}
                <br />
                <br />
                {INFO.address1}
                <br />
                {INFO.address2}
              </h4>
              <h5 className="text-white block md:hidden line-25">
                {INFO.organizationEmail} <br />
                {INFO.organizationPhone1} <br />
                {INFO.organizationPhone2} <br />
                {INFO.address1} <br />
                {INFO.address2}
              </h5>
            </div>
            <div className="w-1/2 sm: w-full text-right">
              <img src={INFO.logoCompany} alt={INFO.altCompany} />
            </div>
          </div>
        </div>
        <Modal
          open={isTermsModalOpen}
          onClose={() => this.handleChange('isTermsModalOpen', false)}
          center
        >
          <TermsConditions />
        </Modal>
      </div>
    );
  }

  handleChange(key, value) {
    this.setState({
      [key]: value,
    });
  }

  handleSubmit() {
    const {
      message,
      fromEmail,
      fromName,
      fromPhoneNumber,
      subjectType,
      lotType,
      acceptTermsAndConditions,
    } = this.state;

    const { house } = this.props;

    if (
      !message ||
      !fromEmail ||
      !fromName ||
      !fromPhoneNumber ||
      !subjectType
    ) {
      alert('Por favor, preencha todos os campos do formulário.');
      return;
    }

    if (!acceptTermsAndConditions) {
      alert('Por favor, aceite os termos e condições.');
      return;
    }

    const { url, apiKey: api_key, to, bcc, sender } = mailSender;

    this.setState({
      isLoading: true,
    });

    const mailSubject =
      subjectType === mailSender.bookKey
        ? mailSender.subjectBooking
        : mailSender.subjectContact;

    const completeMessage = house
      ? `<b>${lotType} | ${getFloorDescription(house.floor)} | ${
          house.side
        }:</b><br/> ${message}`
      : message;

    fetch(url, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        api_key,
        to,
        bcc,
        sender,
        subject: `${mailSubject} ${lotType ? lotType : ''} - ${fromName}`,
        text_body: `${completeMessage}<br/><br/> ${fromName}, <br/> ${fromEmail}, <br/> ${fromPhoneNumber}`,
        html_body: `${completeMessage}<br/><br/> ${fromName}, <br/> ${fromEmail}, <br/> ${fromPhoneNumber}.`,
      }),
    })
      .then(result => {
        if (result.statusText === 'OK') {
          this.sendConfirmationMessage();
          this.showSuccessMessage();
        } else {
          this.showErrorMessage();
        }
      })
      .catch(() => {
        this.showErrorMessage();
      })
      .finally(() => {
        this.setState({
          isLoading: false,
        });
      });
  }

  showSuccessMessage() {
    alert('O e-mail foi enviado com sucesso.');
  }

  showErrorMessage() {
    alert('Não foi possível enviar o e-mail. Por favor, tente novamente.');
  }

  sendConfirmationMessage() {
    const { url, apiKey: api_key, sender } = mailSender;

    fetch(url, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        api_key,
        to: [this.state.fromEmail],
        sender,
        subject: `Comdomínio Fanqueiro 20.24 - Confirmação de contacto`,
        text_body: `Confirmamos a recepção do seu e-mail. Iremos contactá-lo o mais brevemente possível.`,
        html_body: `Confirmamos a recepção do seu e-mail. Iremos contactá-lo o mais brevemente possível.`,
      }),
    });
  }
}

export default ContactForm;
