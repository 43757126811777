const getBluePrint = house => ({
  blueprints: [
    `img/houses/blueprint/house_${house}_0.png`,
    `img/houses/blueprint/house_${house}_1.png`,
  ],
  blueprintsMob: [
    `img/houses/blueprint/house_${house}_0.png`,
    `img/houses/blueprint/house_${house}_1.png`,
  ],
});

const houseSlider = [
  {
    original: 'img/houses/slider/Banner_1.jpg',
    thumbnail: 'img/houses/slider/Banner_1.jpg',
  },
  {
    original: 'img/houses/slider/Banner_2.jpg',
    thumbnail: 'img/houses/slider/Banner_2.jpg',
  },
  {
    original: 'img/houses/slider/Banner_3.jpg',
    thumbnail: 'img/houses/slider/Banner_3.jpg',
  },
  {
    original: 'img/houses/slider/Banner_4.jpg',
    thumbnail: 'img/houses/slider/Banner_4.jpg',
  },
  {
    original: 'img/houses/slider/Banner_5.jpg',
    thumbnail: 'img/houses/slider/Banner_5.jpg',
  },
  {
    original: 'img/houses/slider/Banner_6.jpg',
    thumbnail: 'img/houses/slider/Banner_6.jpg',
  },
];

export const HOUSES_IMAGES = houseSlider;

const MAPA_ACABAMENTOS_A = 'mapa-acabamentos-a.pdf';
const MAPA_ACABAMENTOS_B_C_D = 'mapa-acabamentos-b-c-d.pdf';
const MAPA_ACABAMENTOS_E = 'mapa-acabamentos-e.pdf';

const housesDB = {
  lot1: {
    title: 'Tipologia T4',
    available: true,
    houses: [
      {
        title: 'moradia A',
        status: 'available',
        ...getBluePrint('a'),
        detailSlider: houseSlider,
        mapaAcabamentos: MAPA_ACABAMENTOS_A,
      },
      {
        title: 'moradia B',
        status: 'available',
        ...getBluePrint('b'),
        detailSlider: houseSlider,
        mapaAcabamentos: MAPA_ACABAMENTOS_B_C_D,
      },
      {
        title: 'moradia C',
        status: 'available',
        ...getBluePrint('c'),
        detailSlider: houseSlider,
        mapaAcabamentos: MAPA_ACABAMENTOS_B_C_D,
      },
      {
        title: 'moradia D',
        status: 'available',
        ...getBluePrint('d'),
        detailSlider: houseSlider,
        mapaAcabamentos: MAPA_ACABAMENTOS_B_C_D,
      },
      {
        title: 'moradia E',
        status: 'available',
        ...getBluePrint('e'),
        detailSlider: houseSlider,
        mapaAcabamentos: MAPA_ACABAMENTOS_E,
      },
    ],
  },
};

export const HOUSES = {
  mainImage: {
    path: 'img/houses/main.jpg',
    description: 'Seleccionar moradia',
  },
  housesDB,
};
