import { HOUSES_IMAGES } from './houses';
const NUMBER_IMAGES = 15;
const slider = [];
for (let i = 1; i <= NUMBER_IMAGES; i++) {
  slider.push({
    original: `img/gallery/pic-${i}.jpg`,
    originalAlt: `Imagem ${i + 1} da galeria`,
    thumbnail: `img/gallery/pic-${i}.jpg`,
  });
}

slider.push(...HOUSES_IMAGES);

export const GALLERY = {
  slider,
};
